
// const API_URL = 'https://operations-api.dev.lezzootech.com/api'; 
// const API_URL = 'http://localhost:4008/api';
// const API_URL = 'http://192.168.100.63:4000/api';
//  const API_URL = 'https://operations-api.lezzootech.com/api';
//  const API_URL = 'https://operations-api.lezzootech.com/api';
// const API_URL = 'https://operations-api.dev.lezzoodevs.com/api';

const API_URL = 'https://operations-api.lezzoodevs.com/api';


//  export const API_URL_TASK_OPERATION = 'http://localhost:4000/api';
 export const API_URL_TASK_OPERATION = 'https://lezzoo-task-management-api.lezzoodevs.com/api';


//  export const API_URL_TASK_OPERATION = 'http://localhost:4000/api';
// 
// const API_URL = process.env.REACT_APP_API_URL;


export default API_URL;
// ayman ip
export const CHAT_URL = 'https://chatting-service.lezzootech.com';
// export const CHAT_URL = 'http://192.168.1.10:4011';

// local ip
// export const CHAT_URL = 'http://localhost:4011';


// this is the local storage key for the dark mode
export const darkModeLocalConst = 'ops_theme';

// this permission is for the user to create a new user
export const CreateUser = 'Create User';
// this permission is for the user to edit a new user
export const EditUser = 'Edit User';
// this permission is for the user to close  Merchant
export const CanCloseMerchant = 'Close Merchant';
// this permission is for the user to Reset password
export const ResetPassword = 'Reset Password';
// this permission is for the user to Delete User
export const DeleteUser = 'Delete User';
// this permission is for the user to Create Offline Order
export const CreateOfflineOrder = 'Create Offline Order';

// this permission is for the user to Create Offline Order
export const uploadFibOrders = 'Upload Fib Orders';
// this permission is for the user to subscribe customer to VIP
export const makeCustomerVIP = 'Make Customer VIP';

// this permission is for the user to show customer merchant chat
export const ShowCustomerMerchantChat = 'showCustomerMerchantChat';

// this permission is for the user to show merchant driver chat
export const ShowMerchantDriverChat = 'showMerchantDriverChat';

// this permission is for the user to show customer driver chat
export const ShowCustomerDriverChat = 'showCustomerDriverChat';
export const ShowCustomerAgent = 'ShowCustomerAgent';
export const ShowMerchantAgent = 'ShowMerchantAgent';
export const ShowDriver = 'ShowDriver';
export const ShowDriverSupport = 'ShowDriverSupport';
export const ShowDriverOrder = 'ShowDriverOrder';

export const MerchantPenalties= 'Merchant Penalties';
export const assignIssueToOrder= 'assignIssueToOrder';
export const showUrgentTabInOrder= 'showUrgentTabInOrder';
export const orderDetailsRefund= 'orderDetailsRefund';

export const canUpdateCourierOrderStatus = 'canUpdateCourierOrderStatus';
export const canAssignOrder = 'canAssignOrder';


// those permissions is for the user to block, update and unblock customer
export const canBlockCustomer = 'canBlockCustomer';
export const canUnBlockCustomer = 'canUnBlockCustomer';
export const canUpdateBlockReason = 'canUpdateBlockReason';

// Driver Cash out Permiisons
export const driverCashOut = 'driverCashOut';
export const driverCreateTransaction = 'driverCreateTransaction';
export const driverShowTransactions = 'driverShowTransactions';
export const driverAdvance = 'driverAdvance';
  
export const orderDetailsRefundOnline= 'orderDetailsRefundOnline';
export const editUserMerchant = 'Edit User Merchant';
export const canAssignQaOrder='can_assign_qa_orders'
export const canRemoveAssignedQa='can_remove_assigned_qa'
export const canRemoveAssignedDriver='can_remove_assigned_driver'
export const showQaComments = 'show_qa_comments'
export const addCustomerTags = 'addCustomerTags'

export const daysOfWeek = [
  'saturday',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday'
];

export const citiesLocations = {
    "Erbil": {
      "cord": [36.1900, 44.0098],
      "googleMapLink": "https://www.google.com/maps?q=36.1900,44.0098"
    },
    "Sulaymaniyah": {
      "cord": [35.5611, 45.4309],
      "googleMapLink": "https://www.google.com/maps?q=35.5611,45.4309"
    },
    "Duhok": {
      "cord": [36.8671, 42.9874],
      "googleMapLink": "https://www.google.com/maps?q=36.8671,42.9874"
    },
    "Zakho": {
      "cord": [37.1522, 42.6827],
      "googleMapLink": "https://www.google.com/maps?q=37.1522,42.6827"
    },
    "Baghdad": {
      "cord": [33.3152, 44.3661],
      "googleMapLink": "https://www.google.com/maps?q=33.3152,44.3661"
    },
    "Massif": {
      "cord": [36.3860,44.1904],
      "googleMapLink": "https://www.google.com/maps?q=36.3860,44.1904"
    },
    "Basrah": {
      "cord": [30.5080, 47.7835],
      "googleMapLink": "https://www.google.com/maps?q=30.5080,47.7835"
    },
    "Kirkuk": {
      "cord": [35.4681, 44.3923],
      "googleMapLink": "https://www.google.com/maps?q=35.4681,44.3923"
    },
    "Shaqlawa": {
      "cord": [36.4098, 44.3172],
      "googleMapLink": "https://www.google.com/maps?q=36.4098,44.3172"
    },
    "Akre": {
      "cord": [36.7488, 43.8843],
      "googleMapLink": "https://www.google.com/maps?q=36.7488,43.8843"
    },
    "Koye": {
      "cord": [36.0828, 44.6282],
      "googleMapLink": "https://www.google.com/maps?q=36.0828,44.6282"
    },
    "Halabja": {
      "cord": [35.1770, 45.9864],
      "googleMapLink": "https://www.google.com/maps?q=35.1770,45.9864"
    },
    "Soran": {
      "cord": [36.6533, 44.5457],
      "googleMapLink": "https://www.google.com/maps?q=36.6533,44.5457"
    },
    "Mousl": {
      "cord": [36.3433, 43.1594],
      "googleMapLink": "https://www.google.com/maps?q=36.3433,43.1594"
    },
    "Rumadi": {
      "cord": [33.4258, 43.2994],
      "googleMapLink": "https://www.google.com/maps?q=33.4258,43.2994"
    },
    "Faluja": {
      "cord": [33.3556, 43.7861],
      "googleMapLink": "https://www.google.com/maps?q=33.3556,43.7861"
    },
    "Ranya": {
      "cord": [36.2461, 44.8797],
      "googleMapLink": "https://www.google.com/maps?q=36.2461,44.8797"
    },
    "Qaladze": {
      "cord": [36.1824, 45.1217],
      "googleMapLink": "https://www.google.com/maps?q=36.1824,45.1217"
    },
    "Said Sadq": {
      "cord": [35.3559, 45.8643],
      "googleMapLink": "https://www.google.com/maps?q=35.3559,45.8643"
    },
    "Chwarqurna": {
      "cord": [36.216776, 44.833414],
      "googleMapLink": "https://www.google.com/maps?q=36.2167, 44.8334"
    },
    "Khanaqin": {
      "cord": [34.3482, 45.3867],
      "googleMapLink": "https://www.google.com/maps?q=34.3482,45.3867"
    },
    "Khalifan": {
      "cord": [36.6035, 44.4056],
      "googleMapLink": "https://www.google.com/maps?q=36.6035, 44.4056"
    },
    "Kalar": {
      "cord": [34.6182, 45.3032],
      "googleMapLink": "https://www.google.com/maps?q=34.6182,45.3032"
    },
    "Sharazoor": {
      "cord": [35.315972, 45.68349809],
      "googleMapLink": "https://www.google.com/maps?q=35.315972,45.68349809"
    },
    "Chamchamal": {
      "cord": [35.5335, 44.8359],
      "googleMapLink": "https://www.google.com/maps?q=35.5335,44.8359"
    },
    "Anbar": {
      "cord": [33.4261607615,43.3023329055],
      "googleMapLink": "https://www.google.com/maps?q=33.4261607615,43.3023329055"
    },
    "Diyala": {
      "cord": [33.7412, 44.7110],
      "googleMapLink": "https://www.google.com/maps?q=33.7412,44.7110"
    },
    "Muthannia": {
      "cord": [31.4202, 45.9466],
      "googleMapLink": "https://www.google.com/maps?q=31.4202,45.9466"
    },
    "Diwaniyah": {
      "cord": [31.9876, 44.9230],
      "googleMapLink": "https://www.google.com/maps?q=31.9876,44.9230"
    },
    "Najaf": {
      "cord": [31.9950, 44.3147],
      "googleMapLink": "https://www.google.com/maps?q=31.9950,44.3147"
    },
    "Babil": {
      "cord": [32.5149, 44.2938],
      "googleMapLink": "https://www.google.com/maps?q=32.5149,44.2938"
    },
    "Diqar": {
      "cord": [31.1115, 46.1936],
      "googleMapLink": "https://www.google.com/maps?q=31.1115,46.1936"
    },
    "Karbala": {
      "cord": [32.6160, 44.0240],
      "googleMapLink": "https://www.google.com/maps?q=32.6160,44.0240"
    },
    "Maysan": {
      "cord": [31.8357, 47.1441],
      "googleMapLink": "https://www.google.com/maps?q=31.8357,47.1441"
    },
    "Ninawa": {
      "cord": [36.3456, 43.1254],
      "googleMapLink": "https://www.google.com/maps?q=36.3456,43.1254"
    },
    "Salaheddin": {
      "cord": [34.4464, 43.4865],
      "googleMapLink": "https://www.google.com/maps?q=34.4464,43.4865"
    },
    "Wasit": {
      "cord": [32.4724, 45.8500],
      "googleMapLink": "https://www.google.com/maps?q=32.4724,45.8500"
    }
  }



export const daysOfTheWeek = [
/// week start from staurday
     'Saturday',
     'Sunday',
     'Monday',
     'Tuesday',
     'Wednesday',
     'Thursday',
     'Friday',
    ];

    export const merchantStatusOptions = [
      {
        value: 'all',
        label: 'all'
      },
      {
        value: 'default',
        label: 'default'
      },
      {
        value: 'closed',
        label: 'Closed'
      },
      {
        value: 'busy',
        label: 'Busy'
      },
      {
        value: 'additional',
        label: 'Additional'
      },
      {
        value: 'specific',
        label: 'Specific'
      },
      {
        value: 'temp_closed',
        label: 'Temporary closed'
      }
    ];
    
    export const fixLatLngValues = (value)=>{
      if (value < 91 && value > -91) {
        return value;
      }
      return 0;
    }