import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Image,
  message,
  Row,
  Table,
  TimePicker,
  Tooltip,
} from 'antd';
import classes from './style/task-board-drawer.module.css';
import { useAxiosPut } from '../../configs/axios';
import dayjs from 'dayjs';
import { daysOfTheWeek, daysOfWeek, fixLatLngValues } from '../../configs/constants';
import Map, { Marker } from 'react-map-gl';
import { UilUser } from '@iconscout/react-unicons';
import { CheckCircleFilled, EditOutlined } from '@ant-design/icons';

const TaskCollapse = ({ tasks, copyToClipboard ,onOpenEditWorkingStatusModal}) => {
  const collapseItems = [];

  const initFormValue = {};

  const columns = [
    {
      title: <Tooltip title="Batch Number">B</Tooltip>,
      dataIndex: 'batch_number',
      key: 'batch_number',
      width: 30,
    },
    {
      title: <Tooltip title="Row Sequence">R-C</Tooltip>,
      dataIndex: 'row_sequence',
      key: 'row_sequence',
      width: 60,

    },

    {
      title: <Tooltip title="Batch Issue Name">B-I-N</Tooltip>,
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: <Tooltip title="Batch Source ID">B-C-ID</Tooltip>,
      dataIndex: 'batch_source_id',
      width: 100,

      key: 'batch_source_id',
      render: (text) => (
        <a
          onClick={() => {
            copyToClipboard(text);
          }}
        >
          {text}
        </a>
      ),
    },
    {
      title: <Tooltip title="Merchant Name">B-C-ID</Tooltip>,
      dataIndex: 'merchant_name',
      width: 100,

      key: 'merchant_name',
      render: (text) => {
        try {
          const merchant = JSON.parse(text);
          return merchant.en;
        } catch (error) {
          return '';
        }
      },
    },
    {
      title: <Tooltip title="Batch Source Type">B-C-T</Tooltip>,
      dataIndex: 'batch_source_type',
      key: 'batch_source_type',
      width: 100,

    },
    {
      title: <Tooltip title="Create At">C-T</Tooltip>,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => (
        <span >
          {dayjs(text).format('YYYY-MM-DD HH:mm:ss')}
        </span>
      ),
    },
    {
      title: <Tooltip title="Order Status">Order Status</Tooltip>,
      key: 'order_status',
      dataIndex: 'order_status',  
      render: (text) => (
        <Tooltip title="Status of order">
          <p><b>{text}</b></p>
        </Tooltip>
      ),
    },
    
    {
      title: <Tooltip title="note">Note</Tooltip>,
      dataIndex: 'note',
      key: 'note',
      

      render: (text) => (
        <Tooltip title={text}>
          <p>{text}</p>
        </Tooltip>
      ),
    }
    
  
  ];

  const {
    loading: updateWorkingHourLoading,
    request: updateWorkingHourRequest,
  } = useAxiosPut('/merchants/updateWorkingHour', {
    autoRun: false,
  });
  const onFinish = async (values) => {
    const body = {};
    for (let i = 0; i < daysOfWeek.length; i++) {
      const day = daysOfWeek[i];
      if (values[`start_${day}`]) {
        body[`${day}_open`] = dayjs(values[`start_${day}`]).format('HH:mm:ss');
      }
      if (values[`end_${day}`]) {
        body[`${day}_close`] = dayjs(values[`end_${day}`]).format('HH:mm:ss');
      }
    }
    body.merchant_id = tasks.merchant.source_id;

    await updateWorkingHourRequest(body);
    message.success('Update working hour success');
  };

  if (tasks?.working_days) {
    tasks.working_days.forEach((day) => {
      initFormValue[`start_${day.day_code.toLowerCase()}`] = dayjs(
        day.open_time,
        'HH:mm',
      );
      initFormValue[`end_${day.day_code.toLowerCase()}`] = dayjs(
        day.close_time,
        'HH:mm',
      );
    });
  }

  tasks?.repeatedIssues?.merchant?.length > 0 &&
    collapseItems.push({
      key: '1',
      label: (
        <div className={classes.merchantIssueHeader}>
          <h3>Issue Type</h3>
          <h3>Last 24 hours</h3>
        </div>
      ),
      children: tasks?.repeatedIssues?.merchant.map((item, index) => (
        <div>
          <div
            className={classes[`merchantIssueHeader${!index ? '_first' : ''}`]}
          >
            <span>{item.task_issue_name_en}</span>
            <span>{item.count}</span>
          </div>
          <div className={classes.dividerClass} />
        </div>
      )),
    });

  collapseItems.push({
    key: '2',
    label: (
      <Row justify={'space-between'}>
        <Col>Working Status</Col>
        <Col>{tasks?.merchantWorkingStatus?.merchant_working_status} <Button
          onClick={(e) => {
            e.stopPropagation();
            onOpenEditWorkingStatusModal();
        }}
          
          type='text' danger icon={<EditOutlined />} /></Col>
      </Row>
    ),
    children: (
      <Form layout="vertical" initialValues={initFormValue} onFinish={onFinish}>
        <Row gutter={[10, 10]}>
          {daysOfWeek.map((day, i) => (
            <>
              {' '}
              <Col span={6} key={`${i}_start`}>
                <Form.Item
                  label={`${day}`}
                  name={`start_${day}`}
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <TimePicker
                    placeholder={'Start'}
                    needConfirm={false}
                    showSecond={false}
                    format={'HH:mm'}
                  />
                </Form.Item>
              </Col>
              <Col span={6} key={`${i}_end`}>
                <Form.Item
                  style={{
                    marginTop: 30,
                    marginRight: 10,
                  }}
                  name={`end_${day}`}
                  rules={[
                    {
                      required: true,
                      message: 'Required',
                    },
                  ]}
                >
                  <TimePicker
                    placeholder={'End'}
                    showSecond={false}
                    changeOnBlur={true}
                    format={'HH:mm'}
                  />
                </Form.Item>
              </Col>
            </>
          ))}
        </Row>
        <Row justify={'end'}>
          <Col>
            <Button
              type="primary"
              htmlType="submit"
              loading={updateWorkingHourLoading}
            >
              Update Working Hour{' '}
            </Button>
          </Col>
        </Row>
      </Form>
    ),
  });

  tasks?.batches?.length > 0 &&
    collapseItems.push({
      key: '3',
      label: (
        <Row justify={'space-between'}>
          <Col>Ticket Issues</Col>
          <Col>{tasks?.batches.length}</Col>
        </Row>
      ),
      children: (
        <Table
          scroll={{ y: 300 }}
          dataSource={tasks?.batches}
          columns={columns}
          pagination={false}
        />
      ),
    });

  const images = tasks?.objectMeta?.filter(
    (el) => el.source_value_type === 'image',
  );

  images?.length > 0 &&
    collapseItems.push({
      key: '4',
      label: (
        <Row justify={'space-between'}>
          <Col>Images</Col>
          <Col>{images?.length}</Col>
        </Row>
      ),
      children: images.map((item) => (
        <Image src={item.source_image} width={150} />
      )),
    });

  const tryParse = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch {
      return {};
    }
  };
  const coordinates = tasks?.objectMeta?.find(
    (el) => el.source_value_type === 'coordinate',
  );

  const { longitude, latitude } = tryParse(coordinates?.source_name);

  longitude &&
    latitude &&
    collapseItems.push({
      key: '5',
      label: (
        <Row justify={'space-between'}>
          <Col>Location</Col>
        </Row>
      ),
      children: (
        <Map
          willReadFrequently={true}
          initialViewState={{
            latitude: latitude,
            longitude: longitude,
            zoom: 12,
          }}
          style={{ width: '100%', minHeight: '300px' }}
          mapStyle={'mapbox://styles/mapbox/streets-v12'}
          mapboxAccessToken="pk.eyJ1IjoicmVrYXIiLCJhIjoiY2swYjI1czZzMG5xczNubXd4ODZ5ZmdvNyJ9.UUIH_qL1wzfGoFX2eG6Cyg"
        >
          <Marker latitude={fixLatLngValues(latitude)} longitude={fixLatLngValues(longitude)} anchor="bottom">
            <Tooltip title="Customer">
              <Button
                type="primary"
                shape="circle"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#8e44ad',
                  borderColor: '#8e44ad',
                }}
                icon={<UilUser size="40px" />}
              />
            </Tooltip>
          </Marker>
        </Map>
      ),
    });

    
    {tasks?.driverInfo ? (
      collapseItems.push({
      key: '6',
      label: (
        <Row justify="space-between">
        <Col>Driver Shift</Col>
        <Col><b>{tasks.driverInfo.driverBalance?.amount?.toLocaleString()} IQD</b></Col>
        </Row>
      ),
      children: (
        <CourierShit shift={tryParse(tasks.driverInfo.driverShift?.courier_shift)} />
      ),
      })
    ) : null}

    return (
      <Collapse>
      {collapseItems.map(item => (
        <Collapse.Panel key={item.key} header={item.label}>
        {item.children}
        </Collapse.Panel>
      ))}
      </Collapse>
    );
  };
;
export default TaskCollapse;
const CourierShit = ({shift}) => {
  
  if (!shift?.length) {
    return <span>Shifts are not assigned</span>
  }
  return (
    <Col span={24}>
    <Card gutter={[10, 10]}>
      {daysOfTheWeek.map((day,indexOfDay) => {
        const currentDay = shift?.find((f) => f.day == day);

        return (
          <Card.Grid
            style={{
              width: '40%',
              margin: 10,
              borderRadius: 10,
              cursor: 'pointer',
            }}
          
            
          >
            <span
            className={dayjs().day()==(indexOfDay-1)?classes.todayText:classes.dayText}
            >
              {day }
            </span>
            {currentDay && currentDay.time
              ? currentDay.time.map((t, index) => {
                return (
                  <div
                    className={classes.shiftContainer}
                  >
                    <p>⦿  {changeMinToDayjs(
                      t.from,
                    )} - {changeMinToDayjs(
                      t.to,
                    )}</p>
                    {
                      (dayjs().day()==(indexOfDay)-1&&isShiftStarted(t))
                        ?
                       ( <div className={classes.shiftStatus}>
                        <CheckCircleFilled className={classes.iconShift} />
                          
                        </div>)
                    
                      
                        
                        : ''}
                      </div>
                    );
                  })
                : ' Off'}
        
          </Card.Grid>
        );
      })}
    </Card>
  </Col>
  );
  
}


function changeMinToDayjs(minutes) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    return `${String(hours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
}
function isShiftStarted(shift) {
  const hours = dayjs().hour()*60;
  const minutes = dayjs().minute()+hours;

  return (minutes >= shift.from && minutes <= shift.to) 
}
