import { useEffect, useReducer, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Table,
  Empty,
  Spin,
  Divider,
  message,
  Tooltip,
  Button,
} from 'antd';
import moment from 'moment';
import _ from 'lodash';
import ReactMapGL, { Marker, Popup } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useAxiosGet } from '../configs/axios';
import useLocalStorage from '../configs/localStorage';
import OrderRefundModal from '../components/home/OrderRefundModal';
import { UilUsdCircle } from '@iconscout/react-unicons';
import {
  fixLatLngValues,
  orderDetailsRefund,
  orderDetailsRefundOnline,
} from '../configs/constants';
import RefundOnlinePayment from '../components/order/RefundOnlinePayment';
import TaskDrawer from '../components/TaskBoard/TaskDrawer';
const FindOrder = (props) => {
  const [data, setData] = useState({});
  const [orderChatData, setOrderChatData] = useState([]);
  const [customerChats, setCustomerChats] = useState([]);
  const [resolvedBy, setResolvedBy] = useState(null);
  const [history, setHistory] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [merchantName, setMerchantName] = useState({});
  const [courier, setCourier] = useState({});
  const [merchantRate, setMerchantRate] = useState([]);
  const [deliveryRate, setDeliveryRate] = useState([]);
  const [selectedCurrent, setSelectedCurrent] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderRefundModalVisible, setOrderRefundModalVisible] = useState(false);
  const [orderOnlineRefundModalVisible, setOnlineOrderRefundModalVisible] =
    useState(false);
  const [refundOrder, setRefundOrder] = useState();
  const HEIGHT = window.innerHeight - 200;
  const MAP_SIZE = HEIGHT * 0.8;
  const [viewport, setViewport] = useState({
    latitude: 36.209788,
    longitude: 44.027492,
    altitude: 1.5,
    bearing: 0,
    maxPitch: 60,
    maxZoom: 24,
    zoom: 12,
    minPitch: 0,
    minZoom: 0,
    pitch: 0,
    transitionDuration: 0,
    transitionInterruption: 1,
    width: '90%',
    height: MAP_SIZE,
  });

  const initValue = {
    openTaskDrawer: false,
    selectedTask: null,
    board: null,
  };

  const [states, setStates] = useReducer(
    (currentValue, newValues) => ({
      ...currentValue,
      ...newValues,
    }),
    initValue,
  );

  const onCloseTaskDrawer = () => {
    setStates({
      ...states,
      openTaskDrawer: false,
    });
  };

  const onOpenTaskDrawer = (task) => {
    setStates({
      openTaskDrawer: true,
      selectedTask: task,
    });
  };

  const handleTaskClick = (task) => {
    setStates({
      openTaskDrawer: true,
      selectedTask: task,
    });
  };

  const [auth, setAuth] = useLocalStorage('auth', null);
  if (!auth) {
    return location.replace('/login');
  }
  const orderDetailsRefundPre = auth.user.role.permissions.filter(
    (role) => role.name == orderDetailsRefund,
  ).length;
  const orderDetailsRefundOnlinePre = auth.user.role.permissions.filter(
    (role) => role.name == orderDetailsRefundOnline,
  ).length;

  const height = window.innerHeight;
  const location = window.location.href;
  const orderId = location.substring(location.lastIndexOf('=') + 1);

  const { request: getOrderDetailsRequest, data: orderDetails } = useAxiosGet(
    `orders/findOrder/`,
    {
      orderId,
    },
  );
  const showRefundModal = async () => {
    const editOrder = {
      orderId,
      customer: {
        customer_id: orderDetails.customer_id,
      },
      order_delivery_price: orderDetails.order_delivery_price,
      totalOrderRefund: orderDetails.totalOrderRefund,
      orderTotal: orderDetails.order_total,
    };

    setRefundOrder({
      orderId,
      customer: {
        customer_id: orderDetails.customer_id,
      },
      order_delivery_price: orderDetails.order_delivery_price,
      totalOrderRefund: orderDetails.totalOrderRefund,
      orderTotal: orderDetails.order_total,
    });

    setOrderRefundModalVisible(true);
  };
  useEffect(() => {
    getOrderDetailsRequest({
      orderId,
    }).then((response) => {
      setCustomerChats(response.customerChats);
      setOrderChatData(response.orderChats);
      setMerchantRate(response.merchantRate);
      setDeliveryRate(response.deliveryRate);
      setCourier(response.driver ? response.driver : {});
      setLoading(false);
      setData(response);
      setMerchantName(JSON.parse(response.merchant_name));
      const histories = response.order_status_change_history;
      const historiesArray = [];
      _.forEach(histories, (item) => {
        const history = { ...item };
        if (history.order_change_to == 'cart_updated') {
          try {
            history.order_extra_from =
              item.order_extra_from != ''
                ? JSON.parse(item.order_extra_from)
                : [];
            history.order_extra_to =
              item.order_extra_to != '' ? JSON.parse(item.order_extra_to) : [];
          } catch (error) {}
        } else {
          history.order_extra_from = item.order_extra_from;
          history.order_extra_to = item.order_extra_to;
        }
        historiesArray.push(item);
      });
      setHistory(historiesArray);
      const cartItems = response.cleanCart;
      setCartData(cartItems);
    });
  }, []);

  const columns1 = [
    {
      title: 'Time Point',
      dataIndex: 'timePoint',
      key: 'timePoint',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: 'By',
      dataIndex: 'by',
      key: 'by',
    },
  ];

  const cartDataCols = [
    {
      title: 'Item',
      dataIndex: 'product_name',
      key: 'product_name',
      render: (text, record) => {
        let name = '';
        try {
          name = text.en;
        } catch (error) {}

        if (record.product_tags && record.product_tags.length) {
          return (
            <a
              onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(record.product_tags);
                message.success('Copied to clipboard');
              }}
            >
              {`#${record.product_id} - ${name}`}
            </a>
          );
        }
        return <p> {`#${record.product_id} - ${name}`}</p>;
      },
    },
    {
      title: 'Image',
      dataIndex: 'product_image',
      key: 'product_image',
      render: (text, record) => {
        return (
          <img
            src={record.product_image || record.image}
            style={{
              width: '100px',
              height: '100px',
              objectFit: 'cover',
              borderRadius: '10px',
            }}
          />
        );
      },
    },
    {
      title: 'Price + Addons',
      dataIndex: 'addons',
      key: 'addons',
      render: (text, item) => {
        return (
          <ul
            style={{
              width: '200px',
              fontSize: '12px',
              padding: 0,
            }}
          >
            {item.addons.length > 0 ? (
              item.addons.map((addon, index2) => (
                <li
                  key={index2}
                  style={{
                    marginBottom: '5px',
                  }}
                >
                  <b>{addon.name.en}</b>
                  {` - ${addon.value.name.en} / `}
                  <b>{`${addon.value.price || 0} IQD`}</b>
                </li>
              ))
            ) : (
              <div
                style={{
                  fontSize: '14px',
                  transform: 'translateY(5px)',
                }}
              >
                No addons
              </div>
            )}
          </ul>
        );
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Subtotal',
      dataIndex: 'productTotalPrice',
      key: 'productTotalPrice',
    },
  ];

  const merchsntRateCols = [
    {
      title: 'Star',
      dataIndex: 'feedback_star',
      key: 'feedback_star',
    },
    {
      title: 'Rate',
      dataIndex: 'feedback_title',
      render: (text) => {
        let name = '';
        try {
          name = JSON.parse(text).en;
        } catch (error) {}
        return <p>{name}</p>;
      },
      key: 'feedback_title',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
  ];

  const deliveryRateCols = [
    {
      title: 'State',
      dataIndex: 'feedback_face',
      key: 'feedback_face',
    },
    {
      title: 'Rate',
      dataIndex: 'feedback_title',
      render: (text) => {
        let name = '';
        try {
          name = JSON.parse(text).en;
        } catch (error) {}
        return <p>{name}</p>;
      },
      key: 'feedback_title',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
  ];

  const dataSource = [
    {
      key: '1',
      timePoint: 'Added',
      time: data.order_date_added
        ? moment(data.order_date_added).format('YYYY-MM-DD HH:mm:ss')
        : '0000-00-00 00:00:00',
      by: data.customer_name,
    },
  ];

  if (
    data.order_status == 'canceled' ||
    data.order_status == 'canceledrefund'
  ) {
    dataSource.push({
      key: '2',
      timePoint: 'Canceled',
      time: data.order_declined_time
        ? moment(data.order_declined_time).format('YYYY-MM-DD HH:mm:ss')
        : '0000-00-00 00:00:00',
      by: '',
    });
  } else {
    dataSource.push({
      key: '2',
      timePoint: 'Processed',
      time: data.order_processed_time
        ? moment(data.order_processed_time).format('YYYY-MM-DD HH:mm:ss')
        : '0000-00-00 00:00:00',
      by: '',
    });

    if (data.order_est_readytime != '0000-00-00 00:00:00') {
      dataSource.push({
        key: '3',
        timePoint: 'Estimated',
        time: data.order_est_readytime
          ? moment(data.order_est_readytime).format('YYYY-MM-DD HH:mm:ss')
          : '0000-00-00 00:00:00',
        by: '',
      });
    }
    if (data.order_isready == 1) {
      dataSource.push({
        key: '4',
        timePoint: 'Prepared',
        time: data.order_isreadeytime
          ? moment(data.order_isreadeytime).format('YYYY-MM-DD HH:mm:ss')
          : '0000-00-00 00:00:00',
        by: '',
      });
    }

    dataSource.push(
      {
        key: '5',
        timePoint: 'Driver At Merchant',
        time: data.order_driver_at_merchant
          ? moment(data.order_driver_at_merchant).format('YYYY-MM-DD HH:mm:ss')
          : '0000-00-00 00:00:00',
        by: '',
      },
      {
        key: '6',
        timePoint: 'On the way',
        time: data.order_ontheway_time
          ? moment(data.order_ontheway_time).format('YYYY-MM-DD HH:mm:ss')
          : '0000-00-00 00:00:00',
        by: '',
      },
      {
        key: '7',
        timePoint: 'Completed',
        time: data.order_completed_time
          ? moment(data.order_completed_time).format('YYYY-MM-DD HH:mm:ss')
          : '0000-00-00 00:00:00',
        by: '',
      },
    );
  }
  const columns = [
    {
      title: 'Time',
      dataIndex: 'order_change_date',
      key: 'order_change_date',
      align: 'center',
      render: (text) => <p>{moment(text).format('YYYY-MM-DD HH:mm:ss')}</p>,
    },
    {
      title: 'Duration',
      dataIndex: 'order_change_date',
      key: 'order_change_date',
      render: (text) => <p>{moment(text).from(data.order_date_added)}</p>,
      align: 'center',
    },
    {
      title: 'Status',
      dataIndex: 'order_change_to',
      key: 'order_change_to',
      align: 'center',
    },
    {
      title: 'Comment',
      dataIndex: 'order_change_comment',
      key: 'order_change_comment',
      align: 'center',
    },
    {
      title: 'From',
      dataIndex: 'order_extra_from',
      key: 'order_extra_from',
      render: (text, record) => {
        if (record.order_change_to_id == 24) {
          let parsed = [];
          let array = [];
          try {
            parsed = text != '' ? JSON.parse(text) : [];
          } catch (error) {}
          array = _.map(parsed, (item, index) => {
            let name = '';
            let quantity = 0;
            let categoryName = '';
            try {
              name = item.name.en;
              quantity = item.quantity;
              categoryName = item.product_category_name.en;
            } catch (error) {}
            return (
              <p style={{ textAlign: 'left' }} key={index}>
                <span style={{ fontWeight: 'bold' }}> {categoryName}</span>:
                <span> {name}</span>
                {quantity > 0 ? `- QTY: ${quantity}` : ''}
              </p>
            );
          });
          return array;
        }

        if (record.order_change_to == 'location_changed') {
          return (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${text}`}
              target="_blank"
              rel="noreferrer"
            >
              Old Location
            </a>
          );
        }
        return <p>{text}</p>;
      },
      align: 'center',
    },
    {
      title: 'To',
      dataIndex: 'order_extra_to',
      key: 'order_extra_to',
      render: (text, record) => {
        if (record.order_change_to_id == 24) {
          let parsed = [];
          let array = [];
          try {
            parsed = text != '' ? JSON.parse(text) : [];
          } catch (error) {}
          array = _.map(parsed, (item, index) => {
            let name = '';
            let quantity = 0;
            let categoryName = '';
            try {
              name = item.name.en;
              quantity = item.quantity;
              categoryName = item.product_category_name.en;
            } catch (error) {}
            return (
              <p style={{ textAlign: 'left' }} key={index}>
                <span style={{ fontWeight: 'bold' }}> {categoryName}</span>:
                <span> {name}</span>
                {quantity > 0 ? `- QTY: ${quantity}` : ''}
              </p>
            );
          });
          return array;
        }
        if (record.order_change_to == 'location_changed') {
          return (
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${text}`}
              target="_blank"
              rel="noreferrer"
            >
              New Location
            </a>
          );
        }
        return <p>{text}</p>;
      },
      align: 'center',
    },
    {
      title: 'Source',
      dataIndex: 'order_change_source',
      key: 'order_change_source',
      align: 'center',
    },
    {
      title: 'By',
      dataIndex: 'order_change_by_name',
      key: 'order_change_by_name',
      align: 'center',
    },
  ];

  const orderChatColumns = [
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'By',
      dataIndex: 'senderName',
      key: 'senderName',
    },
    {
      title: 'Sender Source',
      dataIndex: 'messageSource',
      key: 'messageSource',
    },
    {
      title: 'Time',
      dataIndex: 'sendAt',
      key: 'sendAt',
      render: (text) => (
        <p>{moment(text).local().format('YYYY-MM-DD HH:mm:ss')}</p>
      ),
    },
  ];

  if (_.isEmpty(data)) {
    return (
      <div>
        {!loading ? (
          <div
            style={{
              height: `${height * 0.85}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Empty style={{ width: '50%' }} />
          </div>
        ) : (
          <div
            style={{
              height: `${height * 0.85}px`,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin style={{ width: '50%' }} />
          </div>
        )}
      </div>
    );
  }
  const getStatusColor = (status) => {
    switch (status) {
      case 'todo':
        return 'orange';
      case 'in_progress':
        return 'blue';
      case 'done':
        return 'green';
      default:
        return 'red';
    }
  };

  const taskIssueColumns = [
    {
      title: '#',
      dataIndex: 'operation_task_id',
      key: 'operation_task_id',
      render: (text, record) => (
        <span
          onClick={() => handleTaskClick(record)}
          style={{
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
          }}
        >
          {text}
        </span>
      ),
    },
    { title: 'Task Name', dataIndex: 'task_name', key: 'task_name' },
    {
      title: 'Created ',
      dataIndex: '',
      key: 'created_by_created_at',
      render: (record) => (
        <span>
          {record.created_by_name || 'N/A'}
          <br />
          {moment(record.created_at).format('YYYY/MM/DD') || 'N/A'}
          <br />
          {moment(record.created_at).format('HH:mm') || 'N/A'}
        </span>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => customer?.source_name || 'N/A',
    },
    {
      title: 'Merchant',
      dataIndex: 'merchant',
      key: 'merchant',
      render: (merchant) => merchant?.source_name || 'N/A',
    },
    {
      title: 'Driver',
      dataIndex: 'driver',
      key: 'driver',
      render: (driver) => driver?.source_name || 'N/A',
    },
 
    { title: 'Comment', dataIndex: 'task_comment', key: 'task_comment' },
    {
      title: 'Commented By',
      dataIndex: 'task_comment_by_name',
      key: 'task_comment_by_name',
      render: (text) => text || 'N/A',
    },
    {
      title: 'Description',
      dataIndex: 'task_description',
      key: 'task_description',
      render: (text) => text || 'N/A',
    },
    {
      title: 'In Progressed',
      dataIndex: '',
      key: 'in_progress_info',
      render: (record) => (
        <span>
          {record.task_inprogress_by_name || 'N/A'}
          <br />
          {record.task_inprogress_at
            ? moment(record.task_inprogress_at).format('YYYY/MM/DD h:mm A')
            : 'N/A'}
        </span>
      ),
    },
    {
      title: 'Done',
      dataIndex: '',
      key: 'done_info',
      render: (record) => (
        <span>
          {record.task_done_by_name || 'N/A'}
          <br />
          {record.task_done_at
            ? moment(record.task_done_at).format('YYYY/MM/DD h:mm A')
            : 'N/A'}
        </span>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'task_status',
      key: 'task_status',
      render: (text) => (
        <span style={{ color: getStatusColor(text) }}>{text}</span>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <div>
        <h4>Metas</h4>
        {record.metas && record.metas.length > 0 ? (
          <ul>
            {record.metas.map((meta, index) => (
              <li key={index}>{JSON.stringify(meta)}</li>
            ))}
          </ul>
        ) : (
          <p>No metas available</p>
        )}
      </div>
    );
  };

  const onViewportChange = (newViewport) => {
    setViewport({
      ...viewport,
      ...newViewport,
    });
  };

  if (!_.isEmpty(data)) {
    return (
      <div
        style={{
          padding: '15px 0 25px 25px',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'stretch',
            padding: '15px 0',
          }}
        >
          <Card
            style={{
              width: '42.5%',
              background: '#eceff155',
            }}
            title="Order Information"
          >
            <Row>
              <Col span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Order ID:&nbsp;</span>
                  {data.order_id}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Date:&nbsp;</span>
                  {data.order_date_added
                    ? moment(data.order_date_added).format(
                        'YYYY-MM-DD HH:mm:ss',
                      )
                    : '0000-00-00 00:00:00'}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Total:&nbsp;</span>
                  {data.order_total}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Type:&nbsp;</span>
                  {data.device_type}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Customer ID:&nbsp;</span>
                  {data.customer_id}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Customer Name:&nbsp;
                  </span>
                  {data.customer_name}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    Phone Number:&nbsp;
                  </span>
                  {data.customer_phone}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    Order Edited:&nbsp;
                  </span>
                  {data.order_edited === 1 ? <>YES</> : <>NO</>}
                </p>
              </Col>
              <Col span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Payment:&nbsp;</span>
                  {data.payment_type}
                  &nbsp;
                  <span
                    style={{
                      color: data.payment_status === 'Paid' ? 'green' : 'red',
                      fontWeight: 'bold',
                    }}
                  >
                    {data.payment_status}
                  </span>
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}> Driver:&nbsp;</span>
                  {data.order_driver_system == 'lezzoo_courier'
                    ? data.order_driver_name
                    : `${courier.courier_name ? courier.courier_name : ''} - ${
                        courier.courier_phone ? courier.courier_phone : ''
                      }`}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>City:&nbsp;</span>
                  {data.merchant_city}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Distance:&nbsp;</span>
                  {data.distance}
                  &nbsp; km
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Status:&nbsp;</span>
                  {data.order_status}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Merchant Name:&nbsp;
                  </span>
                  {merchantName.en}
                  &nbsp; &nbsp;
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Merchant Zone:&nbsp;
                  </span>
                  {data.hexagonGroup}
                  &nbsp;
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Merchant Phone:&nbsp;
                  </span>
                  {data.merchant_phone}
                  &nbsp;
                </p>
                {orderDetailsRefundPre && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}>Refund:&nbsp;</span>
                    <Tooltip placement="bottom" title="Compensate/Refund">
                      <Button
                        onClick={showRefundModal}
                        style={{
                          background: '#8e44ad',
                          borderColor: '#8e44ad',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        icon={<UilUsdCircle size="22" />}
                      />
                    </Tooltip>
                    &nbsp;
                  </p>
                )}
                {orderDetailsRefundOnlinePre && (
                  <p>
                    <span style={{ fontWeight: 'bold' }}>
                      Online Refund:&nbsp;
                    </span>
                    <Tooltip placement="bottom" title="Online payment refund">
                      <Button
                        onClick={() => {
                          setOnlineOrderRefundModalVisible(true);
                        }}
                        style={{
                          background: '#b59848',
                          borderColor: '#b59848',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        icon={<UilUsdCircle size="22" />}
                      />
                    </Tooltip>
                    &nbsp;
                  </p>
                )}
              </Col>
            </Row>

            {!_.isEmpty(data.cancelationReason) && (
              <div>
                <Divider />
                <Row>
                  <Col span={24}>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>
                        Cancelation Reason:&nbsp;
                      </span>
                      {data.cancelationReason.reason}
                      &nbsp;
                    </p>
                  </Col>
                  <Col span={24}>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>
                        Canceled By:&nbsp;
                      </span>
                      {data.cancelationReason.canceledBy}
                      &nbsp;
                    </p>
                  </Col>
                  <Col span={24}>
                    <p>
                      <span style={{ fontWeight: 'bold' }}>
                        Cancelation Note:&nbsp;
                      </span>
                      {data.cancelationReason.cancelationReasonNote}
                      &nbsp;
                    </p>
                  </Col>
                </Row>
              </div>
            )}

            {!_.isEmpty(data.rejectionReason) && (
              <div>
                <Divider />
                <Row>
                  <Col span={24}>
                    <p>
                      <span
                        style={{
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }}
                      >
                        Rejected On:&nbsp;
                      </span>
                      {data.rejectionReason.rejectedOn}
                      &nbsp;
                    </p>
                  </Col>
                  <Col span={24}>
                    <p>
                      <span
                        style={{
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }}
                      >
                        Rejected Note:&nbsp;
                      </span>
                      {data.rejectionReason.rejectedNote}
                      &nbsp;
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </Card>
          <Table
            style={{
              width: '42.5%',
            }}
            dataSource={dataSource}
            columns={columns1}
            pagination={false}
            bordered
            title={() => 'Order Process History'}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Table
            style={{
              marginTop: '20px',
              width: '90%',
            }}
            dataSource={_.reverse(history)}
            columns={columns}
            pagination={false}
            bordered
            title={() => 'Order Status History'}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Table
            style={{
              marginTop: '20px',
              width: '90%',
            }}
            dataSource={cartData}
            columns={cartDataCols}
            pagination={false}
            bordered
            title={() => 'Order Details'}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            margin: '15px 0',
          }}
        >
          <Card
            title="Order Price Details"
            style={{
              width: '42.5%',
              background: '#eceff155',
            }}
          >
            <Row>
              <Col span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Subtotal:&nbsp;</span>
                  {data.order_price}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Discount:&nbsp;</span>
                  {data.order_discount}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    C. Delivery :&nbsp;
                  </span>
                  {data.order_delivery_price}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>M. Delivery:&nbsp;</span>
                  {data.order_merchant_delivery}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Service:&nbsp;</span>
                  {_.isNull(data.order_service_price)
                    ? 0
                    : data.order_service_price}
                </p>
              </Col>
              <Col span={12}>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Order Tax:&nbsp;</span>
                  {data.order_tax || 0}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>Total:&nbsp;</span>
                  {data.order_total}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Subscription Total:&nbsp;
                  </span>
                  {data.order_subscription_total}
                </p>
                <p>
                  <span style={{ fontWeight: 'bold' }}>
                    Total with Subscription:&nbsp;
                  </span>
                  {parseFloat(data.order_total) +
                    parseFloat(data.order_subscription_total)}
                </p>
              </Col>
            </Row>
          </Card>
          <Card
            title="Instructions"
            style={{
              width: '42.5%',
              background: '#eceff155',
            }}
          >
            <p>
              <span style={{ fontWeight: 'bold' }}>
                Order Instruction:&nbsp;
              </span>
              {data.order_comment}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>
                Delivery Instruction:&nbsp;
              </span>
              {data.order_delivery_instruction}
            </p>
            <p>
              <span style={{ fontWeight: 'bold' }}>Address Note:&nbsp;</span>
              {`${data.order_address} ${data.order_street}`}
            </p>
          </Card>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            margin: '10px 0',
          }}
        >
          <Table
            style={{ width: '42.5%' }}
            dataSource={orderChatData}
            columns={orderChatColumns}
            pagination={false}
            bordered
            scroll={{ y: 300 }}
            title={() => 'Order Chats'}
          />

          <Table
            style={{ width: '42.5%' }}
            dataSource={customerChats}
            columns={orderChatColumns}
            pagination={false}
            bordered
            scroll={{ y: 300 }}
            title={() =>
              `Customer Chats ${resolvedBy ? `Resolved By ${resolvedBy}` : ''}`
            }
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            margin: '20px 0',
          }}
        >
          <Table
            style={{ width: '42.5%' }}
            dataSource={merchantRate}
            columns={merchsntRateCols}
            pagination={false}
            bordered
            title={() => 'Merchant Rate'}
          />

          <Table
            style={{ width: '42.5%' }}
            dataSource={deliveryRate}
            columns={deliveryRateCols}
            pagination={false}
            bordered
            title={() => 'Delivery Rate'}
          />
        </div>

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            marginTop: '20px',
            marginBottom: '20px',
          
          }}
        >
          <Table
            style={{ width: '90%' }}
            caption={() => 'Order Issue Details'}
            title={() => 'Order Issue Tasks'}
            dataSource={orderDetails?.tasks || []}
            columns={taskIssueColumns}
            bordered
            pagination={false}
            expandable={{ expandedRowRender, showExpandColumn: true }}
          
          />

          {states.openTaskDrawer && (
            <TaskDrawer
              task={states.selectedTask}
              onClose={onCloseTaskDrawer}
              onOpenTaskDrawer={onOpenTaskDrawer}
              // statusText={'In Progress'}
              // statusCode={'todo'}
              // StatusIcon={ToInProgress}
              // mediaData={dataTasks?.media}
              // {...extraProps}
            />
          )}
        </div>
        <div
          style={{
            display: 'flex',
            height: '800px',
            justifyContent: 'center',
            alignSelf: 'center',
          paddingRight: '5%',
          paddingLeft: '5%',
          width: '100%',
          marginTop: '20px',
            // zIndex: 1
          }}
        >
          <ReactMapGL
            mapStyle="mapbox://styles/mapbox/streets-v11"
            initialViewState={{
              latitude: 36.209788,
              longitude: 44.027492,
              altitude: 1.5,
              bearing: 0,
              maxPitch: 60,
              maxZoom: 24,
              zoom: 12,
            }}
            mapboxAccessToken="pk.eyJ1IjoicmVrYXIiLCJhIjoiY2swYjI1czZzMG5xczNubXd4ODZ5ZmdvNyJ9.UUIH_qL1wzfGoFX2eG6Cyg"
          >
            <Marker
              key="customer icon"
              latitude={fixLatLngValues(parseFloat(data.merchant_latitude))}
              longitude={fixLatLngValues(parseFloat(data.merchant_longitude))}
            >
              <img
                src="https://d2yugwrr6or5n1.cloudfront.net/uploads/resturant.png"
                alt="merchant icon"
                width="20"
                height="32"
                onClick={(e) => {
                  setSelectedMerchant(true);
                  setSelectedOrder(false);
                  setSelectedCurrent(false);
                }}
              />
            </Marker>
            {data.driver_coordinate && (
              <Marker
                key="customer icon"
                latitude={fixLatLngValues(data.driver_coordinate.latitude)}
                longitude={fixLatLngValues(data.driver_coordinate.longitude)}
              >
                <img
                  src="https://cdn-icons-png.flaticon.com/512/168/168286.png"
                  alt="driver icon"
                  width="30"
                  height="30"
                />
              </Marker>
            )}
            {selectedMerchant ? (
              <Popup
                latitude={parseFloat(data.merchant_latitude)}
                longitude={parseFloat(data.merchant_longitude)}
                onClose={() => {
                  setSelectedMerchant(false);
                }}
                style={{ background: '#eceff155' }}
              >
                <div>
                  <p style={{ marginTop: '5px' }}>
                    Merchant:&nbsp;
                    {merchantName.en}
                  </p>
                  <p>
                    Phone:&nbsp;
                    {data.merchant_phone}
                  </p>
                  <p>
                    Zone:&nbsp;
                    {data.hexagonGroup}
                  </p>
                </div>
              </Popup>
            ) : null}

            {data.order_latitude_now == '' && data.order_longitude_now == '' ? (
              <>
                <Marker
                  key="current order location"
                  latitude={fixLatLngValues(parseFloat(data.order_latitude))}
                  longitude={fixLatLngValues(parseFloat(data.order_longitude))}
                >
                  <img
                    src="https://d2yugwrr6or5n1.cloudfront.net/uploads/home.png"
                    alt="current customer icon"
                    width="20"
                    height="32"
                    onClick={(e) => {
                      setSelectedOrder(true);
                      setSelectedMerchant(false);
                      setSelectedCurrent(false);
                    }}
                  />
                </Marker>
                {selectedOrder ? (
                  <Popup
                    latitude={parseFloat(data.order_latitude)}
                    longitude={parseFloat(data.order_longitude)}
                    onClose={() => {
                      setSelectedOrder(false);
                    }}
                    style={{ background: '#eceff155' }}
                  >
                    <div>
                      <p style={{ marginTop: '5px' }}>
                        Customer:&nbsp;
                        {data.customer_name}
                        &nbsp;
                        {data.customer_last_name}
                      </p>
                      <p>
                        Order Id:&nbsp;
                        {data.order_id}
                      </p>
                      <p>
                        Phone:&nbsp;
                        {data.customer_phone}
                      </p>
                    </div>
                  </Popup>
                ) : null}
              </>
            ) : parseFloat(data.order_latitude_now) ==
                parseFloat(data.order_latitude) &&
              parseFloat(data.order_longitude_now) ==
                parseFloat(data.order_longitude) ? (
              <>
                <Marker
                  key="current order location"
                  latitude={fixLatLngValues(parseFloat(data.order_latitude))}
                  longitude={fixLatLngValues(parseFloat(data.order_longitude))}
                >
                  <img
                    src="https://d2yugwrr6or5n1.cloudfront.net/uploads/home.png"
                    alt="current customer icon"
                    width="20"
                    height="32"
                    onClick={(e) => {
                      setSelectedOrder(true);
                      setSelectedMerchant(false);
                      setSelectedCurrent(false);
                    }}
                  />
                </Marker>
                {selectedOrder ? (
                  <Popup
                    latitude={parseFloat(data.order_latitude)}
                    longitude={parseFloat(data.order_longitude)}
                    onClose={() => {
                      setSelectedOrder(false);
                    }}
                    style={{ background: '#eceff155' }}
                  >
                    <div>
                      <p style={{ marginTop: '5px' }}>
                        Customer:&nbsp;
                        {data.customer_name}
                        &nbsp;
                        {data.customer_last_name}
                      </p>
                      <p>
                        Order Id:&nbsp;
                        {data.order_id}
                      </p>
                      <p>
                        Phone:&nbsp;
                        {data.customer_phone}
                      </p>
                    </div>
                  </Popup>
                ) : null}
              </>
            ) : (
              <>
                <Marker
                  key="current order location"
                  latitude={fixLatLngValues(parseFloat(data.order_latitude))}
                  longitude={fixLatLngValues(parseFloat(data.order_longitude))}
                >
                  <img
                    src="https://d2yugwrr6or5n1.cloudfront.net/uploads/home.png"
                    alt="current customer icon"
                    width="20"
                    height="32"
                    onClick={() => {
                      setSelectedOrder(true);
                      setSelectedMerchant(false);
                      setSelectedCurrent(false);
                    }}
                  />
                </Marker>

                {selectedOrder ? (
                  <Popup
                    latitude={parseFloat(data.order_latitude)}
                    longitude={parseFloat(data.order_longitude)}
                    onClose={() => {
                      setSelectedOrder(false);
                    }}
                    style={{ background: '#eceff155' }}
                  >
                    <div>
                      <p style={{ marginTop: '5px' }}>
                        Customer:&nbsp;
                        {data.customer_name}
                        &nbsp;
                        {data.customer_last_name}
                      </p>
                      <p>
                        Order Id:&nbsp;
                        {data.order_id}
                      </p>
                      <p>
                        Phone:&nbsp;
                        {data.customer_phone}
                      </p>
                    </div>
                  </Popup>
                ) : null}

                <Marker
                  key="customer order location"
                  latitude={fixLatLngValues(
                    parseFloat(data.order_latitude_now),
                  )}
                  longitude={fixLatLngValues(
                    parseFloat(data.order_longitude_now),
                  )}
                >
                  <img
                    src="https://d2yugwrr6or5n1.cloudfront.net/uploads/user.png"
                    alt="user icon"
                    width="20"
                    height="26"
                    onClick={(e) => {
                      setSelectedCurrent(true);
                      setSelectedMerchant(false);
                      setSelectedOrder(false);
                    }}
                  />
                </Marker>

                {selectedCurrent ? (
                  <Popup
                    latitude={parseFloat(data.order_latitude_now)}
                    longitude={parseFloat(data.order_longitude_now)}
                    onClose={() => {
                      setSelectedCurrent(false);
                    }}
                    style={{ background: '#eceff155' }}
                  >
                    <div>
                      <p style={{ paddingTop: '10px' }}>
                        Customer Location When Ordered
                      </p>
                    </div>
                  </Popup>
                ) : null}
              </>
            )}
          </ReactMapGL>
        </div>

         
        {orderDetailsRefundPre && orderRefundModalVisible ? (
          <OrderRefundModal
            status={orderRefundModalVisible}
            setStatus={setOrderRefundModalVisible}
            selectedOrder={refundOrder}
          />
        ):null}
        {orderDetailsRefundOnlinePre && orderOnlineRefundModalVisible ? (
          <RefundOnlinePayment
            open={orderOnlineRefundModalVisible}
            setOpen={setOnlineOrderRefundModalVisible}
            orderId={orderId}
          />
        ):null}
      </div>
    );
  }
};

export default FindOrder;
